<template>
  <p class="sense">
    <slot />
  </p>
</template>

<script>
export default {
  name: "Sense",
};
</script>

<style lang="sass">
.sense
    margin-left: 2rem
</style>
